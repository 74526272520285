import React from "react";
import {
  setCurrentUser,
  setPrices,
  setCurrentCountry,
  setRedirect,
  setRemoteData,
  setRemoteHenchmens,
  setLanguage,
} from "../../redux/actions/actions";
import {
  getCurrentUser,
  getPrices,
  getRemoteData,
  setRemoteOpts,
} from "../../utils/roboApi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Preloader from "../Preloader/Preloader";

//const defaultUserId = process.env.REACT_APP_API_URL.includes('prod') ? 268789457 : 197724920;
// const defaultUserId = 268789457;
//const defaultUserId = 64190794;
const defaultUserId = 0;
// const defaultUserId = 5769157838; //referral new
// const defaultUserId = 420934215;

function RoboApiProvider({ children }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const currentUser = useSelector((state) => state.currentUser);
  const remoteData = useSelector((state) => state.remoteData);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const userId = () => {
    const data = queryParams.get("user_id");
    if (data) {
      return parseInt(data.replace("/", ""));
    }
  };

  const getNavPage = () => queryParams.get("path");

  function getUser(id = defaultUserId) {
    const navPage = getNavPage();
    getCurrentUser(id)
      .then((res) => {
        setCurrentUser(res);
        setCurrentCountry(res.domainId);
        setIsLoading(false);
        if (res.lang) {
          if (res.lang !== "ru") {
            setLanguage("en");
          }
        }
        if (!res.activeUser || res.usedTrafficLastTwoWeek === 0) {
          navigate("/intro");
        } else {
          if (navPage) {
            navigate(navPage);
            setRedirect(true);
          } else navigate("/");
        }
        console.log(res);
      })
      .catch(() => {
        setIsLoading(false);
        navigate("/error");
      });
  }

  function getRemote(id = defaultUserId) {
    console.log(currentUser);
    if (currentUser && currentUser.owner === 1) {
      getRemoteData(id)
        .then((res) => {
          setRemoteData(res);
          console.log(res);
        })
        .catch(() => {
          navigate("/error");
        });
    }
  }

  // function getHenchmens(id = 1474024898) {
  //   if(currentUser && currentUser.owner === 1) {
  //   getRemoteData(id)
  //   .then((res) => {
  //     setRemoteHenchmens(res.data.henchmens);
  //   })
  //   .catch(() => {
  //     navigate('/error');
  //   })
  // }
  // }

  function getHenchmens() {
    if (
      currentUser &&
      currentUser.owner === 1 &&
      remoteData &&
      remoteData.data
    ) {
      const henchmens = remoteData.data.henchmens;
      setRemoteHenchmens(henchmens);
    }
  }

  React.useEffect(() => {
    getUser(userId());
  }, []);

  React.useEffect(() => {
    getRemote(userId());
  }, [currentUser]);

  React.useEffect(() => {
    getHenchmens();
  }, [currentUser, remoteData]);

  React.useEffect(() => {
    getPrices()
      .then((res) => {
        setPrices(res);
      })
      .catch((err) => {
        navigate("/error");
      });
  }, []);

  return <>{!isLoading ? children : <Preloader />}</>;
}

export default RoboApiProvider;
