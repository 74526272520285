import React from "react";
import "./Tariffes.css";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import BackButton from "../BackButton/BackButton";
import { useSelector } from "react-redux";
import { setDirection, setPayment } from "../../redux/actions/actions";
import CurrentTariffWidget from "../CurrentTariffWidget/CurrentTariffWidget";
import { motion } from "framer-motion";
import { directionVariants } from "../../utils/directionOptions";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import AppButton from "../AppButton/AppButton";
import TariffOwn from "../TariffOwn/TariffOwn";
import TariffFit from "../TariffFit/TariffFit";
import TariffNolimit from "../TariffNolimit/TariffNolimit";
import TariffesList from "../TariffesList/TariffesList";
import TariffesTemplatePopup from "../TariffesTemplatePopup/TariffesTemplatePopup";
import { parseTimestamp } from "../../utils/helpers";
import { translations } from "../../utils/translations/translations";

function Tariffes() {
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState("");
  const language = useSelector((state) => state.language);
  const [isHidden, setIsHidden] = React.useState(true);
  const [isRecommendHidden, setIsRecommendHidden] = React.useState(true);
  const [isGbHidden, setIsGbHidden] = React.useState(true);
  const [isMoreHidden, setIsMoreHidden] = React.useState(true);
  const [isFreeInfoHidden, setIsFreeInfoHidden] = React.useState(true);
  const [ownBurger, setOwnBurger] = React.useState(false);
  const [ownBurgerCountry, setOwnBurgerCountry] = React.useState(false);
  const [ownFirst, setOwnFirst] = React.useState(false);
  const [isWithRoboBalance, setIsWithRoboBalance] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const direction = useSelector((state) => state.direction);
  const currentUser = useSelector((state) => state.currentUser);
  const remoteData = useSelector((state) => state.remoteData);
  const isNeverOwn = currentUser.owner === 0 && currentUser.own_tested === 0;
  const isTestOwnTariff =
    currentUser.own_tested === 1 &&
    (remoteData?.data?.tariff === "ownme_test" ||
      remoteData?.data?.tariff === "ownfam_test" ||
      remoteData?.data?.tariff === "owncom_test");
  const isOwnTariff =
    currentUser.owner === 1 &&
    remoteData?.data?.tariff !== "ownme_test" &&
    remoteData?.data?.tariff !== "ownfam_test" &&
    remoteData?.data?.tariff !== "owncom_test";
  const tariffType = remoteData?.data?.tariff?.replace(/[^a-zA-Z]/g, "");
  // 1678368993 - закончился
  // 1679077973 - еще есть
  // const currentUser = {
  //   tariff: '20',
  //   endActiveDate: 1679077973 * 1000,
  //   bonusDate: 1677921307591.322,
  //   smart: 0,
  //   smartTraffic: 0,
  //   regularTraffic: 5,
  //   bonusTrafficNow: '5',
  //   bonusTrafficNextMonth: '',
  //   referralTraffic: 0
  // }
  const [resPrice, setResPrice] = React.useState();
  const today = new Date();
  const endActive = new Date(currentUser.endActiveDate);

  const getBonus = (bonus) => {
    return bonus === null || bonus === "" || bonus === 0
      ? 0
      : parseInt(bonus.replace(/[^0-9]/g), "");
  };

  const getTestedDays = () => {
    const endDate = new Date(remoteData?.data?.endDate);
    const diffInMs = Math.abs(today - endDate);
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
    return diffInDays;
  };

  const isFree =
    ((/^\d+$/.test(currentUser.tariff) &&
      currentUser.smartTraffic <= 0 &&
      currentUser.referralTraffic <= 0 &&
      currentUser.regularTraffic <= 0 &&
      getBonus(currentUser.bonusTrafficNow) !== 0) ||
      (currentUser.tariff.replace(/[^a-zA-Z]+/g, "").toLowerCase() ===
        "nolimit" &&
        endActive < today)) &&
    (getBonus(currentUser.bonusTrafficNow) > 0 ||
      currentUser.referralTraffic > 0);

  React.useEffect(() => {
    location.pathname === "/tariffes" && setValue("");
  }, [location]);

  function handleError(text) {
    setError(text);
    setTimeout(() => setError(""), 5000);
  }

  function handleResPrice(price) {
    setResPrice(price);
  }

  function handleSetIsWithRoboBalance(value) {
    setIsWithRoboBalance(value);
  }

  function handleRedirectAndPayment() {
    if (
      value === "fit" ||
      value === "own" ||
      value === "nolimit" ||
      value === ""
    ) {
      handleError(translations[language].tariffes.errorPackage);
      return;
    } else {
      setDirection(true);
      // currentUser.discount
      //   ? setPayment(Math.floor(value - (value / 100) * currentUser.discount))
      //   : setPayment(parseInt(resPrice));
      setPayment(parseInt(resPrice));

      navigate("/payment", {
        state: {
          isWithRoboBalance,
        },
      });
    }
  }

  function handleChooseClick() {
    if (value.length) {
      setDirection(true);
      navigate(`/tariffes/${value}`);
    } else {
      handleError(translations[language].tariffes.errorTariff);
    }
  }

  const getOwnTariffTypeGb = () => {
    if (tariffType === "ownme") {
      return 500;
    }
    if (tariffType === "ownfam") {
      return 1000;
    }

    if (tariffType === "owncom") {
      return 2000;
    }
    if (remoteData?.data?.tariff?.includes("_test")) {
      return 50;
    }
  };

  return (
    <>
      <motion.section
        className={`tariffes ${
          ownFirst && "own-first"
        } ${location.pathname.replace("/tariffes/", "")}`}
        initial={direction ? "fromLeft" : "fromRight"}
        animate={{
          x: 0,
          opacity: 1,
          transition: { duration: 0.2, delay: 0.2 },
        }}
        exit={direction ? "exitToRight" : "exitToLeft"}
        variants={directionVariants}
      >
        <div className="tariffes_top_block">
          {location.pathname === "/tariffes" && (
            <BackButton
              path={-1}
              text={translations[language].appButton.mainMenu}
              currentClass="wide"
              title={translations[language].textTips.tariffes}
            />
          )}
          {location.pathname === "/tariffes" &&
            (currentUser.owner === 1 ? (
              isNeverOwn ? (
                <div className="tariffes-template__speed">
                  {translations[language].tariffOwn.ownSpeed1}
                  <br />
                  {translations[language].tariffOwn.ownSpeed2}
                </div>
              ) : isOwnTariff ? (
                <div className="tariffes-template__speed">
                  {translations[language].tariffOwn.ownWidget1}
                  {","}
                  {remoteData?.data?.endDate > today
                    ? translations[language].tariffOwn.ownWidget5 +
                      parseTimestamp(remoteData?.data?.endDate)
                    : null}
                  <br />
                  {remoteData?.data?.endDate > today
                    ? remoteData?.data?.trafficTotal > 0
                      ? translations[language].tariffOwn.ownWidget7 +
                        (remoteData?.data?.nextGB
                          ? parseTimestamp(remoteData?.data?.nextGB)
                          : parseTimestamp(remoteData?.data?.endDate)) +
                        translations[language].tariffOwn.ownWidget8 +
                        remoteData?.data?.trafficTotal +
                        translations[language].textTips.gb
                      : translations[language].tariffOwn.ownWidget9 +
                        getOwnTariffTypeGb() +
                        translations[language].textTips.gb +
                        " " +
                        (remoteData?.data?.nextGB
                          ? parseTimestamp(remoteData?.data?.nextGB)
                          : parseTimestamp(remoteData?.data?.endDate))
                    : null}
                </div>
              ) : isTestOwnTariff && remoteData?.data?.endDate > today ? (
                <div className="tariffes-template__speed">
                  {translations[language].tariffOwn.ownWidget1}
                  <br />
                  {translations[language].tariffOwn.ownWidget2}
                  {getTestedDays()}
                  {translations[language].tariffOwn.ownWidget10}
                </div>
              ) : (
                <div className="tariffes-template__speed">
                  {translations[language].tariffOwn.ownWidget1}
                  <br />
                  {translations[language].tariffOwn.ownWidget4}
                </div>
              )
            ) : (
              <CurrentTariffWidget isTariffesList={true} />
            ))}
        </div>
        <div className="tariffes_internal_content">
          <Routes>
            <Route
              exact
              path="/"
              element={
                <TariffesList
                  value={value}
                  setValue={setValue}
                  error={error}
                  handleError={handleError}
                />
              }
            />
            <Route
              path="/own"
              element={
                <TariffOwn
                  // setIsHidden={setIsHidden}
                  // setIsFreeInfoHidden={setIsFreeInfoHidden}
                  value={value}
                  setValue={setValue}
                  onSetIsWithRoboBalance={handleSetIsWithRoboBalance}
                  isWithRoboBalance={isWithRoboBalance}
                  handleError={handleError}
                  handler={handleRedirectAndPayment}
                  setIsHidden={setIsHidden}
                  handleResPrice={handleResPrice}
                  setIsMoreHidden={setIsMoreHidden}
                  setOwnBurger={setOwnBurger}
                  setOwnBurgerCountry={setOwnBurgerCountry}
                  setOwnFirst={setOwnFirst}
                />
              }
            />
            <Route
              path="/fit"
              element={
                <TariffFit
                  handler={handleRedirectAndPayment}
                  value={value}
                  setValue={setValue}
                  onSetIsWithRoboBalance={handleSetIsWithRoboBalance}
                  isWithRoboBalance={isWithRoboBalance}
                  error={error}
                  setIsHidden={setIsHidden}
                  setIsGbHidden={setIsGbHidden}
                  setIsRecommendHidden={setIsRecommendHidden}
                  setIsMoreHidden={setIsMoreHidden}
                  handleResPrice={handleResPrice}
                />
              }
            />
            <Route
              path="/nolimit"
              element={
                <TariffNolimit
                  handler={handleRedirectAndPayment}
                  error={error}
                  value={value}
                  onSetIsWithRoboBalance={handleSetIsWithRoboBalance}
                  isWithRoboBalance={isWithRoboBalance}
                  setValue={setValue}
                  setIsHidden={setIsHidden}
                  setIsMoreHidden={setIsMoreHidden}
                  handleResPrice={handleResPrice}
                />
              }
            />
          </Routes>
        </div>
        {location.pathname === "/tariffes" && (
          <div className="tariffes__content-secondary">
            <span className="tariffes__error">{error}</span>
            <div className="tariffes__button-box">
              <AppButton
                text={translations[language].appButton.choose}
                currentClass="primary white bg-blue"
                handler={handleChooseClick}
              />
            </div>
            <span className="tariffes__tips">
              {translations[language].tariffesList.tariffesListTips}
            </span>
          </div>
        )}
      </motion.section>
      <TariffesTemplatePopup
        isHidden={isHidden}
        setIsHidden={setIsHidden}
        currentClass={location.pathname.replace("/tariffes/", "")}
        buttonText={translations[language].tariffes.tariffPopupTitleRobo}
      >
        <span className="tariffes-template-popup__info-subtitle">
          {translations[language].tariffes.tariffPopupTextRoboT1}
        </span>
        <p className="tariffes-template-popup__info-text">
          {translations[language].tariffes.tariffPopupTextRoboA1}
        </p>
        <span className="tariffes-template-popup__info-subtitle">
          {translations[language].tariffes.tariffPopupTextRoboT2}
        </span>
        <p className="tariffes-template-popup__info-text">
          {translations[language].tariffes.tariffPopupTextRoboA2}
        </p>
        <span className="tariffes-template-popup__info-subtitle">
          {translations[language].tariffes.tariffPopupTextRoboT3}
        </span>
        <p className="tariffes-template-popup__info-text">
          {translations[language].tariffes.tariffPopupTextRoboA3}
        </p>
        <span className="tariffes-template-popup__info-subtitle">
          {translations[language].tariffes.tariffPopupTextRoboT4}
        </span>
        <p className="tariffes-template-popup__info-text">
          {translations[language].tariffes.tariffPopupTextRoboA4}
        </p>
        <span className="tariffes-template-popup__info-subtitle">
          {translations[language].tariffes.tariffPopupTextRoboT5}
        </span>
        <p className="tariffes-template-popup__info-text">
          {translations[language].tariffes.tariffPopupTextRoboA5}
        </p>
      </TariffesTemplatePopup>
      <TariffesTemplatePopup
        isHidden={isFreeInfoHidden}
        setIsHidden={setIsFreeInfoHidden}
        currentClass={location.pathname.replace("/tariffes/", "")}
        buttonText={translations[language].tariffes.tariffPopupTitleFree}
      >
        {currentUser.tariff.replace(/[^a-zA-Z]+/g, "").toLowerCase() ===
          "nolimit" && (
          <div className="tariffes__free-widget-info">
            <p className="tariffes__free-widget-info-text">
              {translations[language].tariffes.tariffPopupTextFreeNolimit1}
              {/^\d+$/.test(currentUser.tariff)
                ? "FIT"
                : currentUser.tariff.replace(/[^a-zA-Z]+/g, "").toUpperCase()}
              {currentUser.smart === 1 && translations[language].tariffes.smart}
              {" —"}
            </p>
            <p className="tariffes__free-widget-info-text">
              {endActive < today
                ? translations[language].tariffes.tariffPopupTextFreeNolimit5
                : translations[language].tariffes.tariffPopupTextFreeNolimit2}
              {currentUser.smart === 1 && translations[language].tariffes.smart}
              ,
            </p>
            <p className="tariffes__free-widget-info-text">
              {translations[language].tariffes.tariffPopupTextFreeNolimit3}
            </p>
          </div>
        )}

        {/^\d+$/.test(currentUser.tariff) && (
          <div className="tariffes__free-widget-info">
            <p className="tariffes__free-widget-info-text">
              {translations[language].tariffes.tariffPopupTextFreeFitNoBonus1}
              {translations[language].tariffes.tariffPopupTextFreeFitNoBonus2}
            </p>
            <p className="tariffes__free-widget-info-text">
              {translations[language].tariffes.tariffPopupTextFreeFitNoBonus3}
            </p>
            <p className="tariffes__free-widget-info-text">
              {translations[language].tariffes.tariffPopupTextFreeFitNoBonus4}
            </p>
            <p className="tariffes__free-widget-info-text">
              {translations[language].tariffes.tariffPopupTextFreeFitNoBonus5}
            </p>
            {(getBonus(currentUser.bonusTrafficNextMonth) !== 0 ||
              getBonus(currentUser.bonusTrafficNow)) !== 0 && (
              <p className="tariffes__free-widget-info-text">
                {translations[language].tariffes.tariffPopupTextFreeFitNoBonus6}
                {isFree ? "BONUS" : "FIT"}
                {currentUser.smart === 1 &&
                  translations[language].tariffes.smart}
                {translations[language].tariffes.tariffPopupTextFreeFitNoBonus7}
                {getBonus(currentUser.bonusTrafficNow) === 0 &&
                  getBonus(currentUser.bonusTrafficNextMonth) !== 0 &&
                  getBonus(currentUser.bonusTrafficNextMonth)}
                {getBonus(currentUser.bonusTrafficNow) !== 0 &&
                  getBonus(currentUser.bonusTrafficNextMonth) === 0 &&
                  (getBonus(currentUser.bonusTrafficNow) === 0
                    ? "0"
                    : getBonus(currentUser.bonusTrafficNow))}
                {translations[language].textTips.gb}

                {getBonus(currentUser.bonusTrafficNow) === 0 &&
                  getBonus(currentUser.bonusTrafficNextMonth) !== 0 &&
                  translations[language].tariffes
                    .tariffPopupTextFreeFitNoBonus8 +
                    parseTimestamp(currentUser.bonusDate)}

                {getBonus(currentUser.bonusTrafficNow) !== 0 &&
                  getBonus(currentUser.bonusTrafficNextMonth) === 0 &&
                  translations[language].tariffes
                    .tariffPopupTextFreeFitAndBonus}

                {/* {getBonus(currentUser.bonusTrafficNow) !== 0 && getBonus(currentUser.bonusTrafficNextMonth) === 0 && (
                  parseTimestamp(currentUser.bonusDate)
                )} */}
              </p>
            )}

            {getBonus(currentUser.bonusTrafficNextMonth) === 0 &&
              getBonus(currentUser.bonusTrafficNow) === 0 && (
                <div>
                  <p className="tariffes__free-widget-info-text">
                    {
                      translations[language].tariffes
                        .tariffPopupTextFreeNoFitAndBonus1
                    }
                  </p>
                  <p className="tariffes__free-widget-info-text">
                    {
                      translations[language].tariffes
                        .tariffPopupTextFreeNoFitAndBonus2
                    }
                    {currentUser.bonusDate > 0
                      ? parseTimestamp(currentUser.bonusDate)
                      : parseTimestamp(currentUser.endDate)}
                  </p>
                </div>
              )}
          </div>
        )}
      </TariffesTemplatePopup>
      <TariffesTemplatePopup
        isHidden={isRecommendHidden}
        setIsHidden={setIsRecommendHidden}
        currentClass={location.pathname.replace("/tariffes/", "")}
        buttonText={translations[language].tariffes.tariffPopupTitleReccomend}
      >
        <p className="tariffes-template-popup__info-text">
          {translations[language].tariffes.tariffPopupTextReccomend}
        </p>
      </TariffesTemplatePopup>
      <TariffesTemplatePopup
        isHidden={isGbHidden}
        setIsHidden={setIsGbHidden}
        currentClass={location.pathname.replace("/tariffes/", "")}
        buttonText={translations[language].tariffes.tariffPopupTitleGbHow}
      >
        <p className="tariffes-template-popup__info-text">
          {/* {translations[language].tariffes.tariffPopupTextGbHowT1}
          <br/>
          <br/>
          {translations[language].tariffes.tariffPopupTextGbHowT2}
          <br/>
          <br/>
          {translations[language].tariffes.tariffPopupTextGbHowT3}
          {getBonus(currentUser.bonusTrafficNextMonth) === 0 ? '0' : getBonus(currentUser.bonusTrafficNextMonth)}
          {translations[language].tariffes.tariffPopupTextGbHowT4} */}
          {translations[language].tariffes.tariffPopup3gb}
        </p>
      </TariffesTemplatePopup>

      <TariffesTemplatePopup
        isHidden={isMoreHidden}
        setIsHidden={setIsMoreHidden}
        currentClass={
          location.pathname.replace("/tariffes/", "") === "fit"
            ? "fit-more"
            : location.pathname.replace("/tariffes/", "") === "nolimit"
            ? "nolimit-more"
            : "own-more"
        }
        isMore={true}
        buttonText={""}
      >
        <p className="tariffes-template-popup__info-text">
          {translations[language].tariffes.tariffesSmarMoreText}
        </p>
      </TariffesTemplatePopup>
    </>
  );
}

export default Tariffes;
